.HelperWrapper .HelperArea {
  margin-top: -0.75rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
}

.HelperWrapper .HelperArea .icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}
.HelperWrapper .HelperArea .icon.error {
  color: #d62e2e !important;
}

.HelperWrapper .HelperArea .icon.valid {
  color: #6bc7ca !important;
}

.HelperWrapper .HelperText {
  display: block;
  font-family: Noto Sans, sans-serif;
  font-size: 0.75rem;
  color: #757575;
  line-height: 1.4;
  white-space: pre-wrap;
}

.HelperWrapper .HelperText.error {
  color: #d62e2e !important;
}
